/* Tailwind */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Grid Layout */
@import '../node_modules/react-grid-layout/css/styles.css';
@import '../node_modules/react-resizable/css/styles.css';

/* Google Font */
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Amatic+SC&family=Architects+Daughter&family=Arvo&family=Cabin&family=Comfortaa&family=Comforter+Brush&family=Concert+One&family=Dancing+Script&family=Estonia&family=Exo+2:wght@200&family=Hubballi&family=Karla:wght@200&family=Lobster&family=Lora&family=Mali&family=Montserrat:wght@200&family=Orbitron&family=Permanent+Marker&family=Play&family=Poiret+One&family=Poppins&family=Rakkas&family=Red+Hat+Display&family=Righteous&family=Roboto:wght@300&family=Sacramento&family=Source+Sans+Pro:wght@200&family=Titillium+Web&family=Yatra+One&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mart0 { margin-top: 0px; }
.mart2 { margin-top: 2%; }
.mart5 { margin-top: 5%; }
.mart7 { margin-top: 7%; }
.mart10 { margin-top: 10%; }

.p-6 { padding: 6%; }
.p-7 { padding: 7%; }
.p-8 { padding: 8%; }
.p-9 { padding: 9%; }
.p-10 { padding: 10%; }
.p-11 { padding: 11%; }
.p-12 { padding: 12%; }
.p-13 { padding: 13%; }
.p-14 { padding: 6%; }
.p-15 { padding: 15%; }

.m-6 { margin: 6%; }
.m-7 { margin: 7%; }
.m-8 { margin: 8%; }
.m-9 { margin: 9%; }
.m-10 { margin: 10%; }
.m-11 { margin: 11%; }
.m-12 { margin: 12%; }
.m-13 { margin: 13%; }
.m-14 { margin: 6%; }
.m-15 { margin: 15%; }

.modal-90w { max-width: 90%; }

.fixed-background {   
  min-height: 500px; 
}

.fixed-background-old { 
  background: top right no-repeat; 
  background-repeat: no-repeat;
  background-attachment:fixed; 
  background-size: cover;
  min-height: 500px; 
}

.brand-logo { height: 40px; }
.slide-logo { max-width: 50%; margin-left: auto; margin-right: auto; }

.simple-map { padding-bottom: 5%;}

.home-container { margin-top: 100px; }

.container-alert { position: fixed; bottom: 10%; z-index: 1050; text-align: center; }

.small-jumbotron {padding:24px 8px 24px 8px; }

/* Site list */
.site-list-header { border: 1px solid #777; background-color: #777; color: #FFF; font-size: 1.3em; }

.site-list-item .logo { margin: 5px; }

.page-boxes { padding: 5px; }
.page-box:hover .box-header { display: block; }
.view-xxs,.view-xs,.view-sm,.view-md,.view-lg { display: none; }
/* @media(max-width:480px){
  .view-xxs { display: block; }
} */
@media(max-width:768px) {
  .view-xs { display: block; }
}
@media(min-width:769px) and (max-width:1200px) {
  .view-sm { display: block; }
}
/* @media(min-width:997px) and (max-width:1200px) {
  .view-md { display: block; }
} */
@media(min-width:1201px) {
  .view-lg { display: block; }
}

.card { background: transparent;}

.fb-page { width: 100%; text-align: center;  }

.youtube-video {width: 100%; text-align: center; margin-left: auto; margin-right: auto; }

.editor-inline { border:1px solid #888; border-radius: 5px; padding-top: 7px; }
.editor-inline:hover { background-color: #EEE; }
